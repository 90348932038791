import React from "react";
import classes from "./WhyHeroSection.module.css";

function WhyHeroSection() {
  return (
    <div className={classes.whyHero}>
      <div className={classes.wrapper}>
        <h1>
          Video has always been at the top of the funnel. That's about to
          change.
        </h1>
      </div>
    </div>
  );
}

export default WhyHeroSection;
