import React from "react";
import Page from "../../components/Page/Page";
import WhyHeroSection from "../../sections/WhyHeroSection/WhyHeroSection";
import ColumnLayout from "../../sections/ColumnSection/ColumnLayout";
import Testimonial from "../../sections/TestimonialSection/Testimonial";
import classes from "./Why.module.css";
import RequestDemoCallToAction from "../../components/RequestDemoCallToAction/RequestDemoCallToAction";
import SecondaryHeroSection from "../../sections/SecondaryHeroSection/SecondaryHeroSection";

function Why() {
  return (
    <Page title="Why VideoSquirrel">
      <WhyHeroSection />

      <SecondaryHeroSection>
        <p>
          <strong>65% of consumers</strong> are much more likely to buy a
          product online after watching a video (ComScore).
        </p>

        <p>
          Videos are the <strong>top point of discovery</strong> for the social
          generation. Brands invest in the best digital content for their
          products, but the process of converting those videos into sales is
          tedious and outdated. Customers go through a multi-click journey
          between experiencing the content and making a transaction. Marketers
          then have to use multiple tools to track the success of those videos.
        </p>
      </SecondaryHeroSection>

      <ColumnLayout reversed className={classes.facts}>
        <div>
          <h2>26% of Consumers</h2>
          <p>
            say it's because the checkout process was too long or complicated.
          </p>

          <h2>34% of Consumers</h2>
          <p>
            say the reason that they abandoned a purchase is that a site
            required an account to be made.
          </p>
        </div>
        <div>
          <img src={require("./photo-grid.png")} alt="" />
        </div>
      </ColumnLayout>
      <ColumnLayout className={classes.darkened} darkened>
        <div>
          <h2>
            Video Squirrel takes your video marketing and makes it into an
            actionable point of sale.
          </h2>

          <p>
            Customers can seamlessly make a purchase within their video
            experience. Brands can easily track the sales success of their
            marketing content. Friction points become a part of the past.
          </p>
        </div>

        <div>
          <img src={require("./phones.png")} alt="" />
        </div>
      </ColumnLayout>
      <ColumnLayout className={classes.testimonial}>
        <div>
          <Testimonial
            testimonial="Social video ad spending in the US will reach $14.89 billion in 2021, growing 44% from 2019. It will then account for 30.4% of total video ad spending."
            author="Emarketer"
            reference="https://content-na1.emarketer.com/social-video-ad-spending-will-grow-44-by-2021"
          />
        </div>
        <div>
          <img
            src={require("./maximize-your-roi.png")}
            alt=""
            style={{ marginBottom: "2em" }}
          />

          <RequestDemoCallToAction
            buttonLabel="Maximize Your ROI"
            placeholder="Enter your email"
            section="Maximize Your ROI"
            secondary
          />
        </div>
      </ColumnLayout>
    </Page>
  );
}

export default Why;
